/* .doc-viewer__header-title {
    display: none;
}

.react-doc-viewer__toolbar .download-button {
    display: none;
} */


#react-doc-viewer #header-bar {
    display: none;
    /* background: linear-gradient(315deg, #9E3C5D, #432F60); */
}

#react-doc-viewer #pdf-controls {
    background: #d8d8d8;
}


#react-doc-viewer #pdf-controls #pdf-zoom-out {
    background: linear-gradient(135deg, #674f87, #b74770);
    /* border: 1px solid #fff; */
    width: 50px;
    height: 30px;
    border-radius: 5px;
}

#react-doc-viewer #pdf-controls #pdf-zoom-out svg path {
    fill: #fff;
}


#react-doc-viewer #pdf-controls #pdf-zoom-in {
    background: linear-gradient(135deg, #674f87, #b74770);
    /* border: 1px solid #fff; */
    width: 50px;
    height: 30px;
    border-radius: 5px;
}

#react-doc-viewer #pdf-controls #pdf-zoom-in svg path {
    fill: #fff;
}


#react-doc-viewer #pdf-controls #pdf-zoom-reset {
    background: linear-gradient(135deg, #674f87, #b74770);
    /* border: 1px solid #fff; */
    width: 50px;
    height: 30px;
    border-radius: 5px;
}

#react-doc-viewer #pdf-controls #pdf-zoom-reset svg path {
    fill: #fff;
}


#react-doc-viewer #pdf-controls #pdf-download {
    display: none;
    background: linear-gradient(135deg, #674f87, #b74770);
    /* border: 1px solid #fff; */
    width: 50px;
    height: 30px;
    border-radius: 5px;
}

#react-doc-viewer #pdf-controls #pdf-download svg path {
    fill: #fff;
}

#react-doc-viewer #pdf-controls #pdf-download svg polygon {
    fill: #fff;
}


#react-doc-viewer #pdf-controls #pdf-pagination-prev {
    background: linear-gradient(135deg, #674f87, #b74770);
    /* border: 1px solid #fff; */
    width: 50px;
    height: 30px;
    border-radius: 5px;
}

#react-doc-viewer #pdf-controls #pdf-pagination svg path {
    fill: #ffffff;
}

#react-doc-viewer #pdf-controls #pdf-pagination-next {
    background: linear-gradient(135deg, #674f87, #b74770);
    /* border: 1px solid #fff; */
    width: 50px;
    height: 30px;
    border-radius: 5px;
}

#react-doc-viewer #pdf-controls #pdf-pagination-info {
    /* color: #432F60; */
    font-weight: bold;
}


#react-doc-viewer #pdf-controls #pdf-toggle-pagination {
    background: linear-gradient(135deg, #674f87, #b74770);
    /* border: 1px solid #fff; */
    width: 50px;
    height: 30px;
    border-radius: 5px;
}

#react-doc-viewer #pdf-controls #pdf-toggle-pagination svg path {
    fill: #fff;
} 
  