.image-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
    padding: 10px;
    padding-top: 10px;
    overflow-y: scroll;
    max-height: 80vh; /* Adjust as needed */
    max-width: 95%;
  }
  
  .image-item {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    border-radius: '10px';
    border: 1px solid #ddd;
    padding: 10px;
  }
  
  .thumbnail {
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-top: 100%; /* Aspect ratio of 70% */
  }
  
  .image-info {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 0.8rem;
  }
  
  .image-actions {
    display: flex;
    justify-content: space-around;
  }
  
  .image-actions button {
    border: none;
    padding: 5px 10px;
    font-size: 0.5rem;
    cursor: pointer;
  }
  
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .image-grid {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .image-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .image-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  